import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import KlaviyoForm from '../../components/utilities/klaviyo-form'

const SMS = () => {
  return (
    <Layout className="sms-page sms-page__rr">
      <Seo title="Rachael Ray x meati™" noindex />
      <Container fluid className="bg-primary p-0">
        <Row className="g-0" style={{ minHeight: '80vh' }}>
          <Col md={{ span: 6, order: 1 }} xl={7}>
            <StaticImage
              src="../../images/sms/rachael-ray-giveaway.jpg"
              alt="Rachael Ray giveaway box"
              className="h-100"
            />
          </Col>
          <Col md={{ span: 6, order: 0 }} xl={5} className="d-flex align-items-center">
            <div className="p-1 p-sm-2 p-xl-3 mx-auto" style={{ maxWidth: '800px' }}>
              <h1 className="fw-bolder text-light"><span className='h2 d-block mb-0'>Make your kitchen</span>A+++</h1>
              <KlaviyoForm code="REjaYD" />
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default SMS
